<template>
  <div>
    <top></top>
    <navMenu></navMenu>
    <div class="inner clearfix">
      <navbar :newpage="newpage"></navbar>
      <div>
        <chapterid
          class="fl zjleft"
          @chapterid="chapterid"
          @zjgid="zjgid"
          @zjsidCopy="zjsidCopy"
        ></chapterid>
      </div>
      <div class="fr zjright">
        <filterBox
          @difficulty="difficulty"
          @questionTypes="questionTypes"
          :gettypelist="gettypelist"
          :formatypearr="formatypearr"
        ></filterBox>
        <hint :listconhttpnum="listconhttpnum" :serradio="serradio"></hint>
        <paper
          v-if="display"
          :paperlist="listdispose"
          id="homeright"
          v-loading="loading"
          :element-loading-text="this.loadingtext"
        ></paper>
        <div v-else class="zanwu" align="center">
          <img src="@/assets/image/zanwu.png" alt="" />
        </div>
        <pagination
          v-if="listconhttpnum > 10"
          @pagenew="pagenew"
          :initialize="initialize"
          :listconhttpnum="listconhttpnum"
        ></pagination>
      </div>
    </div>
  </div>
</template>

<script>
import top from "@/components/navtop.vue";
import navMenu from "@/components/menuNav.vue";
import URL from "@/api/index";
import chapterid from "../../components/testPaper/chapterid";
import navbar from "../../components/testPaper/navbar";
import filterBox from "../../components/testPaper/filterBox";
import hint from "../../components/testPaper/hint";
import paper from "../../components/testPaper/paper.vue";
import pagination from "../../components/pagination";
import axios from "axios";
export default {
  components: {
    top,
    navMenu,
    chapterid,
    navbar,
    filterBox,
    hint,
    paper,
    pagination,
  },
  data() {
    return {
      display: true,
      nums: 0,
      nums1: 0,
      newpage: 1,
      according: true,
      papernum: "",
      serradio: false,
      paperdata: {
        type: "",
        level: "",
        chapters: "",
        page: "",
        num: "",
      },
      chapteriddata: {
        gid: "",
        sid: 1,
      },
      // 章节
      paperlist: [],
      listdispose: [],
      initialize: "",
      listconhttpnum: "",
      gettypelist: [],
      formatypearr: [
        { fmttype_name: "全部", fmttype_id: 0 },
        { fmttype_name: "容易", fmttype_id: 1 },
        { fmttype_name: "较易", fmttype_id: 2 },
        { fmttype_name: "一般", fmttype_id: 3 },
        { fmttype_name: "较难", fmttype_id: 4 },
        { fmttype_name: "困难", fmttype_id: 5 },
      ],
      loading: false,
      loadingtext: "拼命加载中",
      // 数据上报字段
      client_ip: "",
    };
  },
  created() {
    localStorage.setItem("zjpages", JSON.stringify("/testPaper/ChapterTopics"));
  },
  watch: {
    nums(val) {
      if (this.paperlist.length == val - this.nums1) {
        localStorage.setItem("paperlist", JSON.stringify(this.paperlist));
        this.listdispose = this.paperlist;
      }
    },
  },
  mounted() {
    // 获取收藏列表
    var token = JSON.parse(localStorage.getItem("loginInfo")).token;
    this.collect(token);
    localStorage.setItem("route", JSON.stringify("ChapterTopics"));

    //试题兼容
    setTimeout(() => {
      this.$nextTick(function () {
        if (this.commonsVariable.isMathjaxConfig) {
          this.commonsVariable.initMathjaxConfig();
        }
        // this.commonsVariable.MathQueue("app");//传入组件id，让组件被MathJax渲染
      });
    }, 1000);

    // 数据上报
    // this.getRecordRjw()
    var data = { path: "章节选题", id: "1" };
    localStorage.setItem("optionwork", JSON.stringify(data));
  },
  methods: {
    // 收藏列表
    collect(e) {
      var data = {
        token: e,
        question_type: "",
        question_level: "",
        point_id: "",
        chapter_id: "",
      };
      this.$post(URL.GetQuestionList, data).then((res) => {
        localStorage.setItem("collect", JSON.stringify(res.result));
      });
    },
    //章节题型筛选
    zjgid(val) {
      this.chapteriddata.gid = val;
      this.getQuestionType(this.chapteriddata);
    },
    zjsidCopy(val) {
      this.chapteriddata.sid = val;
      this.getQuestionType(this.chapteriddata);
    },
    //章节试题筛选
    chapterid(val) {
      this.paperdata.chapters = val;
      this.nums = 0;
      this.nums1 = 0;
      this.display = true;
      this.paperlist = [];
      this.loadingtext = "拼命加载中";
      this.listconhttp(this.paperdata);
    },
    difficulty(val) {
      // 难度
      this.paperdata.level = val;
      this.nums = 0;
      this.nums1 = 0;
      this.display = true;
      this.paperlist = [];
      this.loadingtext = "拼命加载中";
      this.listconhttp(this.paperdata);
    },
    questionTypes(val) {
      // 题型
      this.paperdata.type = val;
      this.nums = 0;
      this.nums1 = 0;
      this.display = true;
      this.paperlist = [];
      this.loadingtext = "拼命加载中";
      this.listconhttp(this.paperdata);
    },
    pagenew(val) {
      // 页码
      this.paperdata.page = val;
      this.nums = 0;
      this.nums1 = 0;
      this.display = true;
      this.paperlist = [];
      this.loadingtext = "拼命加载中";
      this.listconhttp(this.paperdata);
    },
    getQuestionType(e) {
      //获取章节题库题型
      var data = {
        sid: e.sid,
        gid: e.gid,
      };
      this.$post(URL.getQuestionType, data).then((res) => {
        this.gettypelist = [];
        this.gettypelist = this.gettypelist
          .concat(
            { type_id: 0, type_name: "全部" }
            // { type_id: "999", type_name: "其他" }
          )
          .concat(res.result);
      });
    },
    // 章节试题列表
    listconhttp(e) {
      var that = this;
      var data = {
        type: e.type,
        level: e.level,
        chapters: e.chapters,
        page: e.page,
        num: e.num,
      };
      that.loading = true;
      that.loadingtext = "拼命加载中";
      setTimeout(() => {
        if (that.paperlist == "") {
          // this.$message.error('拉取失败，请刷新页面重试!');
          that.loadingtext = "拉取失败，请刷新页面重试!";
        } else {
          that.loading = false;
        }
      }, 8000);
      this.$post(URL.getChaptersQues, data).then((res) => {
        that.listconhttpnum = res.result.total;
        that.paperlist = res.result.rows;

        // 为空时提示
        if (that.paperlist.length == 0) {
          that.listdispose = [];
          that.display = false;
        }

        // html地址解析
        that.paperlist.forEach(function (data, index) {
          var html = data.question_text;
          
          var urltext2 = "http://rjwtiku.oss-cn-beijing.aliyuncs.com";
          // store_type 1新 2旧题 判断
          if (data.store_type == 2) {
            // console.log('旧题')
            var urltext = html.match(/(\S*)SYS/)[1]
            that.$get(data.answer).then((res1) => {
              var reg = new RegExp('src="', "g");
              var realurl2 = res1.replace(reg, 'src="' + urltext);
              that.paperlist[index].answer = realurl2;
              that.nums++;
            });
            that.$get(data.question_text).then((res2) => {
              var reg = new RegExp('src="', "g");
              var realurl2 = res2.replace(reg, 'src="' + urltext);
              that.paperlist[index].question_text = realurl2;
              that.nums++;
            });
            that.nums1++;
          } else {
            // console.log('新题')
            //文本题目 //图片路径重新处理
            var reg = new RegExp('src="', "g");
            // 题目
            var question_text = data.question_text.replace(
              reg,
              'src="' + urltext2
            );
            // 选项
            var options_json = data.options_json.replace(
              reg,
              'src="' + urltext2
            );
            // 答案
            var answer = data.answer.replace(reg, 'src="' + urltext2);
            // 解析
            var analysis = data.analysis.replace(reg, 'src="' + urltext2);
            // console.log(that.paperlist[index].question_text)
            that.paperlist[index].question_text = question_text;
            that.paperlist[index].options_json = options_json;
            that.paperlist[index].analysis = analysis;
            if (analysis) {
            } else {
              that.paperlist[index].analysis = "略";
            }
            that.paperlist[index].answer =
              "<div>答案:</div>" +
              answer +
              "<div>解析:</div>" +
              that.paperlist[index].analysis;

            that.nums++;
          }
          //题型难度  转换
          that.gettypelist.forEach(function (data1) {
            if (data.question_type == data1.type_id) {
              that.paperlist[index].question_type = data1.type_name;
            }
          });
          that.formatypearr.forEach(function (data2) {
            if (data.question_level == data2.fmttype_id) {
              that.paperlist[index].question_level = data2.fmttype_name;
            }
          });
          that.loading = false;
        });
        // MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
        // window.MathJax.Hub.Queue([
        //   "Typeset",
        //   MathJax.Hub,
        //   document.getElementById("homeright"),
        // ]);
      });
    },

    // 数据上报
    getRecordRjw() {
      var iteminfo = JSON.parse(localStorage.getItem("loginInfo"));
      axios.get("http://access.rujiaowang.net/GetLogServer").then((res) => {
        this.client_ip = res.data.result.client_ip;
        var differenctime =
          res.data.result.net_ts - Date.parse(new Date()) / 1000;
        // 进入组卷
        var saydata = {
          product_name: "教师工作台",
          log_version: "v2.0.1",
          action: "testgroup_entrance",
          user_id: String(iteminfo.user_id),
          user_role: String(iteminfo.user_type),
          user_name: String(iteminfo.user_nicename),
          ts: String(Number(Date.parse(new Date())) / 1000 + differenctime),
          trace_id: JSON.parse(localStorage.getItem("data_traceid")),
          dev_id: "",
          dev_name: "",
          client_ip: this.client_ip,
          os: "",
          cpu_name: "",
          cpu_use: "",
          app_version: "v2.0.0",
          mem_size: "",
          mem_use: "",
          net: "",
          electric: "",
          extend: "",
          remark: `${iteminfo.user_nicename}` + "进入了组卷页面",
          data: {
            school_name: iteminfo.teacher_info.school_name
              ? `${iteminfo.teacher_info.school_name}`
              : "",
            school_id: iteminfo.teacher_info.school_id
              ? `${iteminfo.teacher_info.school_id}`
              : "",
            trace_id: JSON.parse(localStorage.getItem("prepare_traceid")),
          },
        };
        axios
          .post("http://47.114.114.56:9002/RecordRjw", saydata)
          .then((res) => {});
      });
    },
  },
};
</script>

<style lang='less'>
.zjleft {
  width: 19%;
}
.zjright {
  width: 77%;
}
.zanwu {
  // width: 951px;
  height: 250px;
  background-color: #fff;
  box-shadow: 0px 0px 6px 0px rgba(200, 200, 200, 0.47);
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    height: 50%;
  }
}
</style>