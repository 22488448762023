<!--知识点右侧 -->
<template>
  <div class="box_one">
    <div class="box_one_son">
      <ul>
        <li class="clearfix">
          <span class="fl">年级 :</span>
          <el-select v-model="nianji" @change="chosegrade" class="fr">
            <el-option
              v-for="item in grade"
              :key="item.gid"
              :label="item.grade_name"
              :value="item.gid"
            >
            </el-option>
          </el-select>
        </li>
        <li class="clearfix">
          <span class="fl">科目 :</span>
          <el-select v-model="kemu" @change="chosesubject" class="fr">
            <el-option
              v-for="res in subject"
              :key="res.sid"
              :label="res.subject_name"
              :value="res"
            >
            </el-option>
          </el-select>
        </li>
        <li class="clearfix">
          <span class="fl">课本 :</span>
          <el-select v-model="keben" @change="chosebooklist" class="fr">
            <el-option
              v-for="vue in textbook"
              :key="vue.book_id"
              :label="vue.book_name"
              :value="vue"
            >
            </el-option>
          </el-select>
        </li>
      </ul>
    </div>
    <div>
      <el-tree
       highlight-current
        :data="data"
        node-key="chapter_id"
        :default-expanded-keys="[num]"
        :props="defaultProps"
        @node-click="handleNodeClick"
      >
      </el-tree>
    </div>
  </div>
</template>

<script>
import VueEvent from "@/api/eventBus.js";
import URL from "@/api/index";
export default {
  data() {
    return {
      grade: [],
      subject: [],
      textbook: [],
      nianji: "一年级",
      kemu: "语文",
      keben: "一年级语文上册 人教课标版",
      data: [],
      defaultProps: {
        children: "son",
        label: "chapter_name",
      },
      num: "",
    };
  },
  created() {
    var zjbook_id = JSON.parse(localStorage.getItem("zjbook_id"));
    if (zjbook_id != null) {
    } else {
      localStorage.setItem(
        "zjbook_id",
        JSON.stringify({
          book_id: "d545aCss",
          book_name: "一年级语文上册 人教课标版",
          gid: "1",
          id: 1,
          pid: 2,
          press: "人教版",
          sid: "1",
        })
      );
    }
    var zjsidCopy = JSON.parse(localStorage.getItem("zjsidCopy"));
    if (zjsidCopy != null) {
    } else {
      localStorage.setItem("zjsidCopy", JSON.stringify(1));
    }
    this.nianji = JSON.parse(localStorage.getItem("zjgradeCopy"));
    if (this.nianji != null) {
      this.chosegrade1(this.nianji);
    } else {
      this.nianji = "一年级";
      this.chosegrade1(1);
    }
  },
  mounted() {
    this.gradehttp();
  },
  methods: {
    handleNodeClick(data) {
      localStorage.setItem("pre_zjname", JSON.stringify(data.chapter_name));
      localStorage.setItem("pre_zjid", JSON.stringify(data.chapter_id));
      VueEvent.$emit("tree", data);
      this.$emit("chapterid", data.chapter_id);
    },
    // 年纪切换  初始化
    chosegrade1(e) {
      this.$emit("zjgid", e);
      localStorage.setItem("zjgradeCopy", JSON.stringify(e));
      this.subjecthttp(e); //获取科目列表
      if (JSON.parse(localStorage.getItem("zjsidCopy"))) {
        var texth = {
          gid: e,
          sid: JSON.parse(localStorage.getItem("zjsidCopy")),
        };
      } else {
        var texth = { gid: e, sid: "1" };
      }
      this.textbookhttp(texth); //获取课本列表n
    },
    // 年纪切换
    chosegrade(e) {
      this.grade.forEach((item) => {
        if (item.gid == e) {
          localStorage.setItem("data_nianji", JSON.stringify(item.grade_name));
        }
      });
      this.$emit("zjgid", e);

      localStorage.setItem("zjgradeCopy", JSON.stringify(e));
      localStorage.setItem("zjsidCopy", JSON.stringify(1));

      this.subjecthttp(e); //获取科目列表

      var texth = { gid: e, sid: "1" };
      this.textbookhttp(texth); //获取课本列表n

      localStorage.removeItem("shiti");
      localStorage.removeItem("listtitle");
      localStorage.removeItem("shitilength");
      VueEvent.$emit("basketlist", { a: 0, b: "" });
    },
    // 科目切换
    chosesubject(e) {
      localStorage.setItem("data_kemu", JSON.stringify(e.subject_name));
      // console.log(e);
      VueEvent.$emit("kemu", e);
      this.kemu = e.subject_name;
      this.$emit("zjsidCopy", e.sid);
      localStorage.setItem("zjsidCopy", JSON.stringify(e.sid));

      if (JSON.parse(localStorage.getItem("zjgradeCopy"))) {
        var texth = {
          gid: JSON.parse(localStorage.getItem("zjgradeCopy")),
          sid: e.sid,
        };
      } else {
        var texth = { gid: "1", sid: e.sid };
      }
      this.textbookhttp(texth); //获取课本列表

      localStorage.removeItem("shiti");
      localStorage.removeItem("listtitle");
      localStorage.removeItem("shitilength");
      VueEvent.$emit("basketlist", { a: 0, b: "" });
    },
    // 课本切换
    chosebooklist(e) {
      localStorage.setItem("data_bookid", JSON.stringify(e.book_id));
      VueEvent.$emit("bookid", e.book_id);
      this.keben = e.book_name;
      localStorage.setItem("zjbook_id", JSON.stringify(e));
      if (JSON.parse(localStorage.getItem("zjbook_id"))) {
        this.chapter(JSON.parse(localStorage.getItem("zjbook_id")).book_id); //获取章节列表
      } else {
        this.chapter(e.book_id); //获取章节列表
      }

      localStorage.removeItem("shiti");
      localStorage.removeItem("listtitle");
      localStorage.removeItem("shitilength");
      VueEvent.$emit("basketlist", { a: 0, b: "" });
    },
    //获取所有年级列表
    gradehttp() {
      var that = this;
      this.$get(URL.allgrade).then((res) => {
        that.grade = res.result.grade_list;
        this.grade.forEach((item) => {
          if (item.gid == this.nianji) {
            localStorage.setItem(
              "data_nianji",
              JSON.stringify(item.grade_name)
            );
          }
        });
      });
    },
    //获取科目列表
    subjecthttp(e) {
      var num = JSON.parse(localStorage.getItem("zjsidCopy"));
      var that = this;
      var data = {
        gid: e,
      };
      this.$post(URL.getsubject, data).then((res) => {
        that.subject = res.result.subject_list;
        if (that.subject.length != 0) {
          that.subject.forEach((item) => {
            if (item.sid == num) {
              localStorage.setItem(
                "data_kemu",
                JSON.stringify(item.subject_name)
              );
              that.kemu = item.subject_name;
              this.$emit("zjsidCopy", item.sid);
            }
          });
          // that.kemu = that.subject[num - 1].subject_name;
        } else {
          that.kemu = "";
        }
        // this.$emit("zjsidCopy", that.subject[num - 1].sid);
      });
    },
    //获取课本列表
    textbookhttp(e) {
      var book_id = JSON.parse(localStorage.getItem("zjbook_id")).id;
      var that = this;
      var data = {
        gid: e.gid,
        sid: e.sid,
      };
      this.$post(URL.getbooklist, data).then((res) => {
        that.textbook = res.result.booklist;
        if (e.sid == 1) {
          localStorage.setItem(
            "data_bookid",
            JSON.stringify(res.result.booklist[book_id - 1].book_id)
          );
          this.chapter(res.result.booklist[book_id - 1].book_id);
        } else {
          this.chapter(res.result.booklist[book_id - 1].book_id);
        }
        that.booklist = res.result.booklist;
        // 课本数据分类
        if (that.booklist.length != 0) {
          for (var i = 0; i < that.booklist.length; i++) {
            that.booklist[i].book_name = that.booklist[i].book_name; //课本名称拼接
          }
          that.keben = that.booklist[book_id - 1].book_name;
        } else {
          that.keben = "";
        }
      });
    },
    //获取章节列表
    chapter(e) {
      var that = this;
      var data = {
        cptid: e,
      };
      this.$post(URL.bookchapter, data).then((res) => {
        that.data = res.result.menu;
        that.num = that.data[0].chapter_id;
        localStorage.setItem(
          "data_zjname",
          JSON.stringify(res.result.menu[0].chapter_name)
        );
        this.$emit("chapterid", that.data[0].chapter_id);
        localStorage.setItem(
          "chapterid",
          JSON.stringify(that.data[0].chapter_id)
        );
      });
    },
  },
};
</script>

<style lang="less" scoped>
.box_one {
  margin: 24px 0;
  padding: 10px;
  background: #fff;
  box-shadow: 0px 0px 6px 0px rgba(200, 200, 200, 0.47);
  min-height: 556px;
  .box_one_son {
    border-bottom: 1px solid #eeeeee;
    padding: 0 0 15px;
    ul {
      li {
        font-size: 14px;
        color: #707070;
        margin-top: 20px;
        span {
          line-height: 40px;
        }
        .el-select {
          width: 75% !important;
        }
      }
    }
  }
}
.el-tree/deep/.el-tree-node__label {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
